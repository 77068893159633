import {Router} from '@reach/router';
import React from 'react';
import Helmet from 'react-helmet';
import SEO from '../components/SEO';
import NotFoundPage from './404';
import HomePage from './homepage';
import Refill from './refill';

const Home = () => {
  return (
    <div>
      <SEO title="Home" />
      <Helmet>
        <meta name="IZIFILL" content="IZIFILL" />
        <title>IZIFILL | Just Tap and Refill</title>
        <link rel="canonical" href="https://izifill.id" />
      </Helmet>
      <Router basepath="/">
        <HomePage path="/" />
        <Refill path="/refill" />
        <NotFoundPage default />
      </Router>
    </div>
  );
};

export default Home;
